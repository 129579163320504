module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Unofficial US Open Dining Guide","short_name":"US Open Dining","description":"An unofficial guide to dining near the US Open from Queens Together","start_url":"/","background_color":"#0496ff","theme_color":"#0496ff","display":"standalone","icon":"./src/favicons/qt-192.png","icons":[{"src":"./src/favicons/qt-192.png","sizes":"192x192","type":"image/png"},{"src":"./src/favicons/qt-512.png","sizes":"512x512","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"a31b66af764e2e3d4ef3a5843e402fbd"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
